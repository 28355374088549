@import "styles/variables.scss";

.modal {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  column-gap: 20px;
  row-gap: 20px;

  @media screen and (max-width: $breakpoint-sm) {
    grid-template-columns: minmax(200px, 1fr);
  }
}

.detailsModal {
  height: 80%;

  .detailsModalChild {
    margin: auto;
  }
}

.filterSection {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;

  .selectableList {
    min-width: 150px;
  }
}