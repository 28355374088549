@import "../../../../../styles/variables.scss";

.mainContainer {
  .hiddenContent {
    height: 0;
    width: 0;
    overflow: hidden;
    .results {
      display: flex;
      flex-direction: column;
      height: 1500px;
      width: 1000px;
      font-weight: bold;
      font-size: 1.5rem;

      .sectionTitle {
        color: $green-2;
      }

      .tab {
        color: $green-4;
        margin-top: 1rem;
      }

      .resultMessage {
        color: $blue-3;
        display: flex;
        justify-content: center;
        margin: 1rem 0;

        .message {
          border-radius: 10px;
          padding: 24px;
          background-color: $green-5;
          width: 100%;
          text-align: left;
          font-size: 1rem;
          line-height: 16px;
          letter-spacing: 0px;
          font-weight: normal;
        }
      }

      .actions {
        font-weight: normal;
        font-size: 1rem;
        color: $blue-3;
        text-align: start;
      }

      .subTab {
        color: $green-4;
        margin-top: 1rem;
        font-size: 1rem;
      }

      .barChart {
        max-height: 400px;
      }

      .chart {
        width: 100%;
        height: 300px;
      }

      .table {
        width: 100%;
        height: 100%;
        margin-top: 1rem;
      }

      .flexContent {
        display: flex;
        margin-top: 1rem;
      }
    }
  }
}
