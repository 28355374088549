@import "styles/variables.scss";

.modal {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  column-gap: 20px;
  width: 100%;
  height: 100%;
  row-gap: 20px;
  @media screen and (max-width: $breakpoint-sm) {
    grid-template-columns: minmax(200px, 1fr);
  }
  .centeredItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.modalParentForCreate {
  height: 70%;
  width: 60%;
}

.modalParentForUsage {
  height: 70%;
  width: 80%;

  .usageModal {
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

.detailsModal {
  height: 60%;
  width: 70%;

  .detailsModalChild {
    margin: auto;
  }
}

.filterSection {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;

  .selectableList {
    min-width: 150px;
  }
}
