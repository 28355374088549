@import "../../styles/variables.scss";

.container {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tabsContainer {
    margin-bottom: 25px;
    .tab {
      font-size: 16px;
      color: $blue-3;
      font-weight: bold;
      text-transform: inherit;
    }
  }
}

:global(.MuiTab-textColorPrimary.Mui-selected) {
  color: $green-2 !important;
}
:global(.MuiTabs-indicator) {
  background: $green-2 !important;
}
