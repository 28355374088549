@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/variables.scss";

.mainContainer {
  width: 100%;

  .innerContainer {
    display: flex;
    flex-direction: column;
    .upperContainer {
      min-height: 250px;
    }
    .middleSection {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 30px;
      @media screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
      }

      .result {
        width: 40%;
        @media screen and (max-width: $breakpoint-sm) {
          width: 90%;
        }
      }
      .datePicker {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        .pickerInstructions {
          color: $gray-9;
          font-size: 14px;
        }
        @media screen and (max-width: $breakpoint-sm) {
          margin-top: 20px;
        }
      }
    }
    .table {
      margin-top: 30px;
    }
  }
}
