@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  display: flex;
  height: 100vh;
  width: 90%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  @include width-viewport;
  row-gap: 20px;
  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: $blue-3;
    .title {
      color: $blue-2;
      font-size: 24px;
      font-weight: 700;
    }
    .body {
      margin-top: 25px;
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 40px;
      text-align: start;
    }
  }
  .gif{
    width: 30%;
    min-width: 350px;
  }
}
