@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.wrapper {
  @include light-shadow;

  &.single {
    box-shadow: none;
  }

  .navbarContainer {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    @include width-viewport;

    .image {
      width: 180px;
    }

    .shortImage {
      width: 40px;
    }

    .space {
      width: 100%;
      max-width: 100px;
    }

    .actionsContainer {
      display: flex;
      width: 100%;
      max-width: 800px;
      justify-content: space-between;
      align-items: center;

      &.planPage {
        max-width: 600px;

        @media screen and (max-width: $breakpoint-xlg) {
          max-width: 500px;
        }
      }

      &.user {
        justify-content: flex-end;
      }

      .button {
        color: $blue-3;
        font-weight: bold;

        &:hover {
          text-decoration: underline $green-2 3px;
          text-underline-offset: 5px;
          cursor: pointer;
        }
      }
    }

    .activeButton {
      text-decoration: underline $green-2 3px;
      text-underline-offset: 5px;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .wrapper {
    .navbarContainer {
      padding: 5px;

      .space {
        display: none;
      }

      .shortImage {
        width: 30px;
      }

      .actionsContainer {
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .wrapper {
    .navbarContainer {
      padding: 10px 30px;
    }
  }
}