@import "../../../../../../styles/variables.scss";

.tabsContainer {
  .buttonContainer {
    display: flex;
    justify-content: left;
    .button {
      width: 150px;
      border-radius: 0;
      font-size: 12px;
      font-weight: 500;
      background: $white-1 !important;
      color: $green-4 !important;
      &.button:hover {
        background: $blue-1 !important;
        color: $white-1 !important;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
          0 17px 50px 0 rgba(0, 0, 0, 0.19);
      }
    }
    .selectedMenu {
      background: $green-5 !important;
    }
  }

  .divider {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .tabsContainer {
    .buttonContainer {
      display: flex;
      justify-content: left;

      .button {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .tabsContainer {
    .buttonContainer {
      display: flex;
      justify-content: left;

      .button {
        width: 220px;
        font-size: 16px;
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    .tabsContainer {
      .buttonContainer {
        .button {
          width: 310px;
          font-size: 18px;
        }
      }
    }
  }
}
