@import "../../../styles/variables.scss";

.container {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue-1;
  height: 24px;
  width: 80px;
  min-width: 60px;
  border-radius: 12px;
  color: $white-1;
  font-size: 20px;

  .star {
    margin-right: 5px;
    height: 18px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    font-size: 16px;
    width: 68px;
    height: 22px;
    border-radius: 11px;
    .star {
      height: 16px;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .container {
    font-size: 14px;
    width: 60px;
    height: 20px;
    border-radius: 10px;

    .star {
      height: 14px;
    }
  }
}
