@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .resultContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      .image {
        width: 700px;
      }

      .nonClickableSection {
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
        font-size: 24px;
        font-weight: 500;
        column-gap: 36px;

        .nonClickable {
          background-color: $white-1;
          color: $green-1;
          border: 2px solid $green-1 ;
          border-radius: 30px;
          min-width: 200px;
          padding: 12px 0;

          &.selected {
            background-color: $blue-1;
            color: $white-1;
            font-weight: 700;
            border-color: $blue-1 ;
          }
        }
      }
    }
  }

  .resultSection {
    width: 80%;
    display: flex;
    justify-content: center;
    color: $blue-3;
    font-size: 24px;
    display: inline;
  }

  .goToAucaButton {
    font-weight: bold !important;
    font-size: 20px !important;
    width: 200px;
    margin: 50px 0;
  }
}

@media screen and (max-width: $breakpoint-xlg) {
  .wrapper {
    .container {
      .resultContainer {
        .image {
          width: 500px;
        }

        .nonClickableSection {
          justify-content: space-around;
          font-size: 20px;
          column-gap: 24px;

          .nonClickable {
            min-width: 160px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .wrapper {
    .container {
      .resultContainer {
        .image {
          width: 400px;
        }

        .nonClickableSection {
          font-size: 16px;
          column-gap: 12px;
          row-gap: 12px;

          .nonClickable {
            min-width: 130px;
            max-height: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .wrapper {
    .container {
      .resultContainer {
        .image {
          width: 100%;
        }

        .nonClickableSection {
          font-size: 18px;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .nonClickable {
            width: 100%;
          }
        }
      }
    }
  }
}