@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
  @include width-viewport;
  gap: 30px;

  .element {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      width: 100%;
      color: $green-2;
      font-size: 36px;
      text-align: left;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .content {
      margin-top: 32px;
      color: $blue-3;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      width: 90%;

      .message {
        border-radius: 10px;
        padding: 24px;
        width: 100%;
        background-color: $green-5;
        text-align: left;
        font-size: 20px;
        letter-spacing: 0px;
      }

      .planContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 30px;

        .planSection {
          display: flex;
          flex-direction: column;
          font-size: 24px;
          gap: 20px;

          .upperSection {
            display: flex;
            align-items: center;
            .star {
              margin-right: 10px;
              height: 30px;
            }
            .plan {
              color: $green-6;
              margin-right: 20px;
              font-weight: 700;
              font-size: 24px;
            }
          }
          .planStatus {
            border: 3px solid $green-2;
            color: $green-2;
            padding: 10px 20px;
            border-radius: 25px;
            font-size: 20px;
            display: flex;
            align-items: center;
            .dot {
              height: 10px;
              width: 10px;
              border-radius: 5px;
              background: $green-2;
              margin-right: 10px;
            }
          }
        }
        .paymentSection {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 24px;
          gap: 10px;
          font-size: 16px;

          .plan {
            color: $green-6;
            margin-right: 20px;
            font-weight: 700;
            font-size: 24px;
          }
        }
      }
    }
    .profileContent {
      display: flex;
      width: 90%;
      justify-content: center;
      align-items: center;
      gap: 30px;
      flex-wrap: wrap;

      .photo {
        min-width: 30%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .changeLanguage {
          display: flex;
          align-items: center;
          .label {
            margin-right: 10px;
          }
        }
        .icon {
          font-size: 150px;
        }
      }
      .inputSection {
        display: flex;
        flex-direction: column;
        min-width: 400px;
        width: 50%;

        .input {
          margin-bottom: 15px;
        }
        .saveChanges {
          width: 100%;
          .button {
            display: flex;

            justify-content: flex-end;
          }
        }
        @media screen and (max-width: $breakpoint-sm) {
          min-width: 300px;
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-xlg) {
    margin-top: 100px;
  }
}
