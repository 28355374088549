/*gray*/
$gray-1: #ffffff;
$gray-2: #dedede;
$gray-3: #44494d;
$gray-4: #333333;
$gray-5: #000000;
$gray-6: #0000004d;
$gray-7: #0000001a;
$gray-8: #0000008a;
$gray-9: #849095;
$gray-9-opacity-90:rgba(132, 144, 149, 0.7);

/*blue*/
$blue-1: #1b7b8b;
$blue-2: #007297;
$blue-3: #044075;

/*green*/
$green-1: #9dc900;
$green-2: #8ec14b;
$green-3: #529b6d;
$green-4: #358c7c;
$green-5: #cce2de;
$green-6: #3b8f80;

/*red*/
$red-1: #fd6848;

/*yellow*/
$yellow-1: #fdd247;

/*white*/
$white-1: #ffffff;

/* breakpoints */
$breakpoint-mini: 320px;
$breakpoint-xs: 400px;
$breakpoint-sm: 600px;
$breakpoint-md: 720px;
$breakpoint-md1: 721px;
$breakpoint-lg: 800px;
$breakpoint-xlg: 960px;
$breakpoint-xl: 1024px;
$breakpoint-xxl: 1280px;
$breakpoint-xxxl: 1480px;

/* Table Font size */
$font-size-table-title: 21px;
$font-size-table-title-sm: 23px;
$font-size-table-title-md: 26px;
$font-size-table-title-lg: 30px;
$font-size-table-title-xl: 34px;
$font-size-table-title-xxl: 42px;
$font-size-table-title-xxxl: 48px;

$font-size-table-price: 33px;
$font-size-table-price-sm: 40px;
$font-size-table-price-md: 48px;
$font-size-table-price-lg: 55px;
$font-size-table-price-xl: 63px;
$font-size-table-price-xxl: 72px;
$font-size-table-price-xxxl: 84px;

$font-size-table-cell: 16px;
$font-size-table-cell-sm: 16px;
$font-size-table-cell-md: 16px;
$font-size-table-cell-lg: 16px;
$font-size-table-cell-xl: 18px;
$font-size-table-cell-xxl: 24px;
$font-size-table-cell-xxxl: 26px;

$font-size-table-icon: 14px;
$font-size-table-icon-sm: 23px;
$font-size-table-icon-md: 28px;
$font-size-table-icon-lg: 35px;
$font-size-table-icon-xl: 50px;
$font-size-table-icon-xxl: 90px;
$font-size-table-icon-xxxl: 100px;

$font-size-table-resume: 14px;
$font-size-table-resume-sm: 14px;
$font-size-table-resume-md: 14px;
$font-size-table-resume-lg: 14px;
$font-size-table-resume-xl: 17px;
$font-size-table-resume-xxl: 19px;
$font-size-table-resume-xxxl: 21px;

$font-size: 26px;
