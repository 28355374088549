@import "../../styles/variables.scss";


.description {
  display: flex;
  justify-content: space-between;
  color: $blue-3;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
}

.sliderContainer {
  padding: 0 5px;

  :global(.MuiSlider-valueLabel) {
    color: $blue-3;
    margin-top: 59px;
    background-color: transparent,
  }

  :global(.MuiSlider-rail) {
    border: 10px;
    color: $blue-3;
    opacity: 1,
  }

  :global(.MuiSlider-thumb) {
    border: 4px;
    background-color: $red-1;
  }

  :global(.MuiSlider-markLabel) {
    color: #044075
  }
}

.sliderValue {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
}

@media screen and (min-width: $breakpoint-md) {
  .description {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .sliderContainer {
    padding: 0 5px;
  }

  .sliderValue {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
  }
}