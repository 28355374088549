@import "./variables.scss";

@mixin light-shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

@mixin button-contained-hover {
  background: $gray-3;
}

@mixin button-outlined-hover {
  color: $gray-3;
  border-color: $gray-3;
  background: $gray-1;
}

@mixin responsive-padding {
  padding: 15px 50px;
  @media screen and (max-width: $breakpoint-lg) {
    padding: 5px;
  }
  @media screen and (max-width: $breakpoint-xl) {
    padding: 10px 30px;
  }
}

@mixin body-shadow {
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
}
@mixin content-shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s linear;
  &:hover {
    box-shadow: 5px 5px 20px 6px rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.2s linear;
  }
}

@mixin remove-shadow-viewport {
  @media screen and (max-width: $breakpoint-lg) {
    box-shadow: none;
    filter: none;
    &:hover {
      box-shadow: none;
      filter: none;
    }
  }
}

@mixin width-viewport {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
