@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  @include width-viewport;

  .carouselContainer {
    width: 100%;
  }

  .cardContainer {
    display: flex;
    width: 90%;
    justify-content: center;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    column-gap: 12px;
    row-gap: 32px;
  }
}

@media screen and (min-width: $breakpoint-xxxl) {
  .container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}