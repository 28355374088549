@import "../../../../styles/variables.scss";

.containerCompanyStructure {
  width: 50%;
  margin-top: 15px;
  margin-bottom: 65px;
  margin-left: auto;
  margin-right: auto;
  color: $blue-3;
  font-size: 40px;
  font-weight: 400;


  .flexContainer {
    font-size: $font-size;
    margin-left: 25px;
    margin-bottom: 25px;

    .tree {
      text-align: left;

      .template {
        color: $green-3;
      }

      ul {
        list-style: none;
        line-height: 2em;
      }

      ul summary {
        cursor: pointer;
      }

      ul summary::marker {
        display: none;
        content: " ";
      }

      ul summary::-webkit-details-marker {
        display: none;
        content: " ";
      }

      ul li {
        position: relative;
      }

      ul li::before {
        position: absolute;
        left: -15px;
        top: 0px;
        border-bottom: 4px solid gray;
        content: " ";
        width: 8px;
        height: 1em;
      }

      ul li::after {
        position: absolute;
        left: -15px;
        bottom: 0px;
        border-left: 2px solid gray;
        content: " ";
        width: 8px;
        height: 100%;
      }

      ul summary::before {
        position: absolute;
        font-size: 23px;
        top: 0.6em;
        left: -28px;
        content: "+";
        background: $green-4;
        color: white;
        display: block;
        width: 21px;
        height: 21px;
        border-radius: 1em;
        border: 3px solid $green-2;
        z-index: 10;
        text-align: center;
        line-height: 0.9em;
      }

      ul details[open]>summary::before {
        content: "-";
        background: $gray-9;
      }

      .btnTreeDelete {
        margin: 0 0 0 10px;
        padding: 3px;
        color: rgb(114, 6, 6);
        border-radius: 5px;
      }

      .btnTreeDelete::before {
        content: "⛔ ";
        font-weight: 600;
      }

      .btnTreeAdd {
        margin: 0 0 0 10px;
        padding: 3px;
        color: $blue-3;
        border-radius: 5px;

      }

      .btnTreeAdd::before {
        content: "➕ ";
        font-weight: 600;
      }

      .btnTreeEdit {
        margin: 0 0 0 10px;
        padding: 3px;
        color: $blue-3;
        border-radius: 5px;

      }

      .btnTreeEdit::before {
        content: "🖋 ";
        font-weight: 600;
      }

      .treeItem{
        display: inline-flex;
        justify-content: space-between;
        width: 80%;
        line-height: 1em;
      }
      
      .treeItemTxt{
        text-align: left;
        margin:0;
        padding:0;
        width:60%;

      }
      .treeItemBtn{
        text-align: left;
        margin:0;
        padding:0;
        width:30%;
      }
    }

    .addNode {
      .modalAddNode {
        display: none;
        position: fixed;
        z-index: 12;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: $gray-9-opacity-90;

        .modalAddNodeContent {
          display: flex;
          justify-content: center;
          z-index: 14;
        }

        .modalAddNodeClose {
          position: absolute;
          color: white;
          top: 3%;
          right: 3%;
          padding-top: 10px;
          text-align: left;
          font-weight: 800;
          cursor: pointer;
          z-index: 19;
        }

        .modalAddNodeClose:hover,
        .modalAddNodeClose:focus {
          color: black;
        }

        .dataLeaf {
          position:relative;
          width: 40%;
          padding: 15px;
          margin: 0 15px;
          background-color: $blue-2;
          border-radius: 15px;
          z-index: 16;
        }

        .dataLeaf2 {
          width: 20%;
          padding: 15px;
          z-index: 16;
        }

        .labelTag {
          color: white;
          display: inline-block;
          padding: 5px;
          width: 80%;
          align-items: left;
          font-size: 21px;
          z-index: 17;

          .labelIn {
            background-color: white;
            color: $blue-2;
            width: 80%;
            margin-left: 5%;
            margin-top: 10px;
            font-size: 18px;
            min-height: 18px;
          }

          .labelIn:focus {
            border: 1px solid $green-3;
            background-color: rgb(231, 243, 232);

          }
        }
      }

      .dataTitle {
        color: white;
        font-size: 31px;
        font-weight: bold;
        padding: 0 5px 10px 5px;
        z-index: 17;

        .dataTitleAux {
          font-size: 21px;
          font-weight: normal;
        }
      }

      .modalError {
        display: none;
        position: fixed;
        z-index: 40;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: $gray-9-opacity-90;

        .modalErrorContent {
          position: relative;
          margin: auto;
          padding: 20px;
          width: 80%;
          background-color: $blue-2;
          color: white;
          border-radius: 15px;
          z-index: 50;

          p {
            margin: 0;
            padding: 0;

          }

          .modalErrorClose {
            position: absolute;
            top: 3%;
            right: 3%;
            padding-top: 10px;
            text-align: left;
            // margin:0 15px;
            font-weight: 800;
            cursor: pointer;
            z-index: 60;
          }
        }

      }

      .modalErrorClose:hover,
      .modalErrorClose:focus {
        color: black;
      }

      .btnAddNode {
        margin: -15px 0 0 0;
        padding: 15px;
        color: white;
        background-color: $blue-2;
        font-size: 23px;
        border-radius: 37px;
      }

      .dataSection {
        position: relative;
        z-index: 20;

        .tooltip {
          position: absolute;
          visibility: hidden;
          color: #fff;
          background-color: $green-4;
          font-size: 18px;
          text-align: left;
          bottom: 0%;
          width: 250px;
          height: auto;
          border-radius: 6px;
          padding: 5px 0;
          margin-left: 71%;
          z-index: 30;
          opacity: 0;
          transition: opacity 1s;
        }
      }

      .dataSection:hover .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .titleSection{
    margin-top: 15px;
    margin-bottom: 50px;
  }
}