@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 12px;
  @include body-shadow;
  @include remove-shadow-viewport();

  .container {
    max-width: 310px;
    min-height: 180px;
    border-radius: 16px;
    padding: 35px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: $gray-1;
    font-size: 20px;
    position: relative;

    @media screen and (max-width: $breakpoint-xxl) {
    font-size: 18px;
    }
    @media screen and (max-width: $breakpoint-xl) {
    font-size: 16px;
    }
    @media screen and (max-width: $breakpoint-lg) {
    font-size: 20px;
    }
  }

  .triangle {
    height: 0;
    width: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 40px solid;
  }
}
