@import "../../../../../styles/variables.scss";

.grid {
  width: 90vw;
  height: 60vh;
  .upperActions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
}

.actions {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  color: $blue-3;

  .delete {
    &:hover {
      color: $red-1;
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .edit {
    &:hover {
      color: $green-4;
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.headerCellContainer {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .arrowContainer {
    margin-bottom: 1rem;

    .arrow {
      opacity: 0.1;

      &:hover {
        opacity: 1;
      }
    }

    .selectedColumnArrow {
      opacity: 1;
      color: $blue-3;
    }
  }
}

.deleteModal {
  width: 20%;
  height: 100px;

  .deleteModalChild {
    margin: auto;
  }
}

.table :global(.MuiDataGrid-selectedRowCount) {
  display: none !important;
}

.table :global(.MuiDataGrid-footerContainer) {
  justify-content: flex-end !important;
}

.table :global(.MuiDataGrid-columnsContainer) {
  border: none !important;
}

.table :global(.MuiDataGrid-row.Mui-selected) {
  background: transparent !important;
}

.table :global(.MuiDataGrid-row) {
  &:hover {
    background: transparent !important;
  }
}

.table :global(.MuiDataGrid-iconSeparator) {
  visibility: hidden;
}

.table :global(.MuiDataGrid-cell) {
  &:focus-within,
  &:focus {
    outline: solid transparent 1px !important;
  }
}

.table :global(.MuiDataGrid-columnHeader) {
  &:focus-within,
  &:focus {
    outline: solid transparent 1px !important;
  }
}

.footerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0 0.5rem 0.5rem;

  .paginationContainer {
    display: flex;
    align-items: center;

    .currentPage {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
    }
  }

  .rowsPerPageContainer {
    min-width: 7rem;
  }
}
