:global(.MuiFormControl-root) {
  width: 100%;
}

.container {
  width: 100%;

  .formControl {
    width: 100%;
  }
}

:global(.MuiOutlinedInput-root) {
  padding-right: 0 !important;
}
