@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  max-width: 800px;
  padding: 20px;
  color: $blue-3;
  font-size: 20px;
  @include light-shadow;
  border-radius: 30px;
  .question {
    font-weight: 400;
    text-align: start;
    margin-bottom: 20px;

    .number {
      font-weight: 700;
    }
  }
  .options {
    margin-left: 40px;
    text-align: start;

    .optionRadio {
      margin-bottom: 20px;
    }
  }
  .actions {
    justify-content: flex-end;
    display: flex;

    .nextButton {
      margin-left: 30px;
    }
  }
}
