@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding: 0;
  @include width-viewport;

  .resultMessage {
    margin-top: 32px;
    color: $blue-3;
    display: flex;
    justify-content: center;

    .message {
      border-radius: 10px;
      padding: 24px;
      background-color: $green-5;
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0px;
    }
  }

  .text {
    color: $blue-3;
    font-size: 14px;
    text-align: left;

    .buttonSection {
      margin-top: 25px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;

      .fileInput {
        width: 200px;
      }
    }

    .accents {
      color: $green-1;
    }
  }
}

.modal {
  height: fit-content;
  width: 60%;

  .modalChild {
    margin: auto;
  }
}

  .video {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }


@media screen and (min-width: $breakpoint-md) {
  .container {
    padding: 20px;

    .text {
      font-size: 18px;
    }
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .container {
    .text {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
