@import "../../styles/variables.scss";

.container {
  border-radius: 80px;
  border: 2px solid $green-4;

  .titleContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    color: $green-4;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    position: relative;

    .icon {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .container {
    .titleContainer {
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;

      .icon {
        margin-right: 0px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    border: 3px solid $green-4;

    .titleContainer {
      font-size: 26px;
      font-weight: 500;
      line-height: 26px;

      .accordionTitle {
        margin-right: 24px;
      }
      .badge {
        margin-right: 40px;
      }
      .icon {
        display: flex;
        position: absolute;
        right: 0px;
        align-items: center;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xxl) {
  .container {
    .titleContainer {
      display: flex;
      justify-content: left;
      align-items: center;
      color: $green-4;
      padding: 0px 0px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      position: relative;
      align-self: center;

      .accordionTitle {
        margin-right: 12px;
      }

      .icon {
        display: flex;
        position: absolute;
        right: 0px;
        margin-right: 0px;
        top: 0px;
        align-items: center;
      }
    }
  }
}
