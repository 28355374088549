@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.emptyState {
  color: $red-1;
  width: 70%;
  min-height: 28px;
  font-size: 20px;
  border-radius: 16px;
  border: 2px solid $red-1;
  padding: 12px;
  justify-content: center;
  align-items: center;
  @include width-viewport;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding: 12px;
  @include width-viewport;

  .resultsContainer {
    justify-content: center;
    width: 100%;

    .results {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      column-gap: 84px;
      row-gap: 32px;
      margin-bottom: 32px;

      .resultCard {
        width: 100%;
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: left;

      .button {
        width: 150px;
        color: $green_4;
        border-radius: 0;
        font-size: 12px;
        font-weight: 500;
        background-color: $white-1;
      }

      .button:hover {
        background-color: $blue-1;
        color: $white-1;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
          0 17px 50px 0 rgba(0, 0, 0, 0.19);
      }

      .selectedMenu {
        @extend.button;
        background-color: $green-5;
      }

      .costsButton {
        @extend.button;
      }
    }

    .divider {
      margin-bottom: 24px;
    }

    .tableContainer {
      width: 100%;
      display: flex;
    }

    .resultMessage {
      margin-top: 32px;
      color: $blue-3;
      display: flex;
      justify-content: center;
      margin-bottom: 12px;

      .message {
        border-radius: 10px;
        padding: 24px;
        background-color: $green-5;
        width: 90%;
        text-align: left;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0px;
      }

      .treeEquivalenceMessage {
        text-align: center;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 20px;

        .accents {
          color: $green-4;
        }
      }
    }

    .chartContainer {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 100%;
      justify-content: center;

      .chartMenuContainer {
        display: flex;
        width: 100%;
        height: 40px;
        column-gap: 32px;

        .chartMenuButtonContainer {
          display: flex;
          flex-direction: column;

          .underline {
            margin-top: 3px;
            height: 2px;
            background-color: $green-1;
            border-radius: 8px;
          }

          .chartButton {
            width: auto;
            color: $green_4;
            border: 0px solid transparent;
            font-weight: 500;
            font-size: 12px;
            background-color: $white-1;
            cursor: pointer;
          }
        }
      }

      .chart {
        margin: 24px 0;
        display: flex;
        justify-content: center;
        align-self: center;
        height: 180px;
        width: 220px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .container {
    padding: 20px;

    .resultsContainer {
      .buttonContainer {
        display: flex;
        justify-content: left;

        .button {
          font-size: 14px;
        }
      }

      .results {
        .resultCard {
          width: 80%;
        }
      }

      .chartContainer {
        .chartMenuContainer {
          .chartMenuButtonContainer {
            .underline {
              height: 3px;
            }

            .chartButton {
              font-size: 14px;
            }
          }
        }

        .chart {
          margin: 0;
          height: 280px;
          width: 500px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    padding: 20px;

    .resultsContainer {
      .buttonContainer {
        display: flex;
        justify-content: left;

        .button {
          width: 220px;
          font-size: 16px;
        }
      }

      .results {
        margin: 32px;
        flex-direction: row;
        justify-content: space-around;
      }

      .resultMessage {
        .message {
          font-size: 18px;
          line-height: 20px;
        }

        .treeEquivalenceMessage {
          font-size: 18px;
          line-height: 20px;
        }
      }

      .chartContainer {
        .chartMenuContainer {
          .chartMenuButtonContainer {
            .chartButton {
              font-size: 16px;
            }
          }
        }

        .chart {
          margin: 24px 0;
          height: 300px;
          width: 500px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .container {
    .resultsContainer {
      .buttonContainer {
        .button {
          width: 310px;
          font-size: 18px;
        }
      }

      .chartContainer {
        .chart {
          height: 350px;
          width: 600px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xlg) {
  .container {
    .resultsContainer {
      .chartContainer {
        .chart {
          height: 350px;
          width: 700px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .container {
    .resultsContainer {
      .resultMessage {
        .message {
          font-size: 24px;
          line-height: 28px;
        }

        .treeEquivalenceMessage {
          font-size: 24px;
          line-height: 28px;
        }
      }

      .chartContainer {
        .chart {
          height: 400px;
          width: 800px;
        }
      }
    }
  }
}
