@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  @include width-viewport;

  .button {
    width: 100%;
    color: $green-1;
    border-radius: 0;
    font-size: 18px;
    font-weight: 500;
    background: $white-1 !important;
    border: 2px solid $green-1;
    &:hover {
      box-shadow: 6px 6px 11px 3px rgba(0, 0, 0, 0.25);
      color: $green-1;
    }
    &.selectedMenu {
      @extend.button;
      background: $green-1 !important;
      box-shadow: 6px 6px 11px 3px rgba(0, 0, 0, 0.25);
      color: $white-1;
      border: 2px solid $green-1;
    }
  }
  .proPlanButton {
    width: 100%;
    color: $blue-1;
    border-radius: 0;
    font-size: 18px;
    font-weight: 500;
    background: $white-1 !important;
    border: 2px solid $blue-1;
    &:hover {
      box-shadow: 6px 6px 11px 3px rgba(0, 0, 0, 0.25);
      color: $blue-1;
    }
    &.selectedProMenu {
      @extend.button;
      background: $blue-1 !important;
      box-shadow: 6px 6px 11px 3px rgba(0, 0, 0, 0.25);
      color: $white-1;
      border: 2px solid $blue-1;
      &:hover {
        color: $white-1;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .buttonContainer {
    display: flex;
    flex-direction: row;

    .button {
      font-size: 20px;
    }
    .proPlanButton {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .buttonContainer {
    .button {
      font-size: 32px;
    }
    .proPlanButton {
      font-size: 32px;
    }
  }
}
