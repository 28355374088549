@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.navbar {
  width: 100vw;
  position: fixed;
  background: $gray-1;
  top: 0;
  z-index: 10000;
}

.button {
  margin-bottom: 40px;
}

.button .demoButton {
  border-radius: 9px;
  width: 400px;
  font-size: 20px;
}

.copyright {
  margin-bottom: 25px;
}

.container{
  .text{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    padding: 0;
    @include width-viewport;

    color: $blue-3;
    font-size: 20px;
    text-align: left;
    .accents {
      color: $green-1;
    }
  }

}