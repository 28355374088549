@import "../../../../styles/variables.scss";

.containerFAQSection {
  width: 100%;
  align-self: center;
  margin: auto;
}

.text {
  align-self: center;
  text-align: left;
  width: 60%;
  margin: auto;
  font-size: 12px;
  color: $blue-3;
  scroll-padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
}

@media screen and (min-width: $breakpoint-sm) {
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
}

@media screen and (min-width: $breakpoint-xxxl) {
  .text {
    font-size: 21px;
  }
}
