@import "../../styles/variables.scss";

.modal {
  background: $gray-1;
  width: 40%;
  height: 40%;
  border-radius: 10px;
  margin: 0;
  color: $blue-3;
  padding: 30px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .header {
    text-align: center;
    margin-bottom: 25px;
    color: $green-2;
    font-size: 20px;
    font-weight: 700;
  }

  .children {
    height: 100%;
    overflow-y: scroll;
    padding-top: 5px;
    display: flex;
    width: 100%;
  }

  .actionSection {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    .primaryAction {
      margin-left: 30px;
    }
  }
}

.smallModal {
  background: $gray-1;
  max-width: 600px;
  min-width: 300px;
  max-height: 500px;
  border-radius: 10px;
  margin: 0;
  color: $blue-3;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .header {
    text-align: center;
    margin-bottom: 25px;
    color: $green-2;
    font-size: 20px;
    font-weight: 700;
  }

  .children {
    max-height: 300px;
    padding-top: 5px;
  }

  .actionSection {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    .primaryAction {
      margin-left: 30px;
    }
  }
}

.container:global(.MuiBackdrop-root.MuiModal-backdrop) {
  backdrop-filter: blur(5px);
}
