@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

:global(.MuiMenu-list) {
  & :global(.MuiMenuItem-root) {
    font-size: 14px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding: 12px;
  @include width-viewport;

  .accordionContainer {
    justify-content: center;
    min-width: 100%;

    & :global(.MuiChip-root) {
      @media screen and (max-width: $breakpoint-sm) {
        max-width: 200px;
      }
    }

    .checkboxContainer {
      display: flex;
      margin: 20px 0;
      text-align: left;
      font-weight: 400;
      line-height: 18px;

      & :global(.MuiCheckbox-root) {
        font-size: 14px;
      }

      & :global(.MuiTypography-root) {
        font-size: 14px;
        color: $blue-3;
      }

      & :global(.MuiSvgIcon-root) {
        font-size: 18px;
        color: $green-4;
      }
    }

    .select {
      text-align: left;
      border-radius: 24px;
      width: 100%;
      background-color: $green-5;
      height: auto;
      color: $green-4;
      font-weight: 500;
      line-height: 30px;
      padding: 0;

      & :global(.MuiInputBase-root) {
        font-size: 14px;
        color: $green-4;
        padding: 0;
      }

      & :global(.MuiButtonBase-root) {
        font-size: 12px;
      }

      & :global(.MuiSelect-select) {
        font-size: 14px;
        padding: 0 0 0 12px;
      }

      & :global(.MuiOutlinedInput-notchedOutline) {
        border: 0;
      }
    }

    .sliderContainer {
      margin-bottom: 40px;
    }

    .dailyContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      align-self: center;
      column-gap: 16px;
      row-gap: 32px;

      .label {
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        color: $red-1;
        margin-bottom: 8px;
      }

      .input {
        color: $blue-3;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  :global(.MuiMenu-list) {
    & :global(.MuiMenuItem-root) {
      font-size: 16px;
    }
  }

  .container {
    .accordionContainer {
      .checkboxContainer {
        & :global(.MuiCheckbox-root) {
          font-size: 16px;
        }

        & :global(.MuiTypography-root) {
          font-size: 16px;
        }

        & :global(.MuiSvgIcon-root) {
          font-size: 20px;
        }
      }

      .select {
        border-radius: 36px;

        & :global(.MuiInputBase-root) {
          font-size: 16px;
        }

        & :global(.MuiButtonBase-root) {
          font-size: 14px;
        }

        & :global(.MuiSelect-select) {
          font-size: 16px;
        }
      }

      .dailyContainer {
        flex-direction: row;

        .label {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .base{
        position:relative;
        .baseDataInput{
          width: 60%;
          min-height: 600px;
        }
  
        .baseDataOutput{
          position: absolute;
          width: 30%;
          right:5%;
        }
        
      }


    }
  }
}

@media screen and (min-width: $breakpoint-xl) {
  :global(.MuiMenu-list) {
    & :global(.MuiMenuItem-root) {
      font-size: 18px;
    }
  }

  .container {
    .accordionContainer {
      .checkboxContainer {
        & :global(.MuiCheckbox-root) {
          font-size: 20px;
        }

        & :global(.MuiTypography-root) {
          font-size: 20px;
        }

        & :global(.MuiSvgIcon-root) {
          font-size: 20px;
        }
      }

      .select {
        border-radius: 36px;

        & :global(.MuiInputBase-root) {
          font-size: 20px;
        }

        & :global(.MuiButtonBase-root) {
          font-size: 18px;
        }

        & :global(.MuiSelect-select) {
          font-size: 14px;
          padding: 4px 0 4px 12px;
        }

        & :global(.MuiSelect-select) {
          font-size: 20px;
        }
      }

      .dailyContainer {
        .label {
          font-size: 20px;
        }
      }
    }
  }
}
