@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  width: 100%;
  padding-top: 150px;
  @include width-viewport;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  background-color: $green-5;

  .element {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      color: $green-2;
      width: 100%;

      font-size: 2rem;
      text-align: left;
      font-weight: bold;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 1.5rem;
      }
    }

    .content {
      margin-top: 0.7rem;
      color: $blue-3;
      // display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      width: 90%;

      .label {
        font-weight: bold;
        border-radius: 10px;
        padding: 12px 24px 0px 24px;
        width: 100%;
        text-align: left;
        font-size: 20px;
        letter-spacing: 0px;
      }

      .message {
        border-radius: 10px;
        padding: 0 24px 12px 24px;
        width: 100%;
        text-align: left;
        font-size: 20px;
        letter-spacing: 0px;
      }
    }
  }
}
